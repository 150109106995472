@font-face {
	font-family: "Beer Money";
	src: url("../fonts/beer-money.ttf") format("truetype");
}

html {
	font-size: 15px;
	line-height: 160%;
	scroll-behavior: smooth;
}

body {
	font-family: "Beer Money", decorative;
	color: white;
}

p {
	font-family: 'Gabriela', serif;
	margin-bottom: 0;
}

a:hover {
	text-decoration: none;
}

.Video {
	width: 100%;
	height: 350px;
	margin-top: 40px;
	margin-bottom: 20px;
}

header .Logo,
footer .Logo {
	filter: invert(1);
	transition: all 0.25s;
}

header .Logo:hover,
footer .Logo:hover {
	transform: scale(1.05) rotate(-20deg);
}

header .Logo {
	width: 100%;
}

footer .Logo {
	width: 75%;
	margin: auto;
}

section {
	padding: 25px 0;
	padding-top: 50px;
	box-shadow: 0 0 10px rgb(245, 215, 47);

	background-repeat: no-repeat;
	background-size: cover;
}

.hidden {
	display: none;
}

h1,
.section-title {
	font-size: 2rem;
	text-transform: uppercase;
	color: white;
	text-align: center;
}

.ArrowTop {
	position: fixed;
	right: 25px;
	bottom: 25px;
	z-index: 100;

	width: 50px;
	height: 50px;
	border: none;
	background-color: transparent;
	background-image: url("../img/arrow-top.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;

	transition: all 0.25s;
}

.ArrowTop:hover {
	transform: scale(1.1);
	filter: hue-rotate(90deg);
}

@media(min-width: 1300px) {
	.container {
		max-width: 1280px;
	}
}

@media(max-width: 767px) {
	.ItemMenu a {
		padding: 2px 0 !important;
		font-size: 1.25rem !important;
	}
}

@media(max-width: 576px) {
	.ItemMenu a {
		padding: 2px 0 !important;
		font-size: 1.1rem !important;
	}
}

@media(max-width: 479px) {
	header .Menu {
		flex-wrap: wrap;
		justify-content: center;
	}

	header .ItemMenu a {
		padding: 2px 10px !important;
		font-size: 1rem !important;
	}

	.Intro {
		padding-top: 35px !important;
	}

	.Intro .Video {
		margin-top: 10px;
		height: 40vh;
	}

	.Intro .intro-text {
		padding: 10px !important;
		font-size: 0.9rem !important;
	}

	.ArrowTop {
		width: 35px;
		height: 35px;
	}
}