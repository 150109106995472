*,
*::before,
*::after {
	box-sizing: border-box;
}

img {
	display: block;
}

.scroll-hidden {
	overflow: hidden;
}

.gallery {
	position: relative;
	z-index: 2;
	padding: 10px;
	flex-flow: row wrap;
	justify-content: space-between;
	transition: all .5s ease-in-out;
	transform: translateZ(0);

	&.pop {
		filter: blur(10px);
	}

	figure {
		// flex-basis: 33.333%;
		padding: 10px;
		overflow: hidden;
		cursor: pointer;

		img {
			width: 100%;
			transition: all .3s ease-in-out;
		}

		figcaption {
			display: none;
		}

	}
}

.popup {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: #fff;
	opacity: 0;
	transition: opacity .5s ease-in-out .2s;


	&.pop {
		opacity: 1;
		transition: opacity .2s ease-in-out 0s;

		figure {
			margin-top: 0;
			opacity: 1;
		}
	}

	figure {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transform-origin: 0 0;
		margin-top: 30px;
		opacity: 0;
		animation: poppy 500ms linear both;

		img {
			position: relative;
			z-index: 2;
			//box-shadow: 0 1px 5px rgba(0, 0, 0, .2), 0 6px 30px rgba(0, 0, 0, .4);
		}

		figcaption {
			position: absolute;
			bottom: 50px;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.78));
			z-index: 2;
			width: 100%;
			padding: 100px 20px 20px 20px;
			color: #fff;
			font-family: 'Open Sans', sans-serif;
			font-size: 32px;

			small {
				font-size: 11px;
				display: block;
				text-transform: uppercase;
				margin-top: 12px;
				text-indent: 3px;
				opacity: .7;
				letter-spacing: 1px;
			}
		}

		.shadow {
			position: relative;
			z-index: 1;
			top: -56px;
			margin: 0 auto;
			background-position: center bottom;
			background-repeat: no-repeat;
			width: 98%;
			height: 50px;
			opacity: .9;
			filter: blur(16px) contrast(1.5);
			transform: scale(0.95, -0.7);
			transform-origin: center bottom;
		}
	}

	.hidden {
		display: none;
	}

	.close {
		position: absolute;
		z-index: 3;
		top: 10px;
		right: 10px;
		width: 25px;
		height: 25px;
		cursor: pointer;
		background: url(#close);
		border-radius: 25px;
		background: rgba(0, 0, 0, .1);
		box-shadow: 0 0 3px rgba(0, 0, 0, .2);

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

@keyframes poppy {
	0% {
		transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
	}

	3.4% {
		transform: matrix3d(0.316, 0, 0, 0, 0, 0.407, 0, 0, 0, 0, 1, 0, -94.672, -91.573, 0, 1);
	}

	4.3% {
		transform: matrix3d(0.408, 0, 0, 0, 0, 0.54, 0, 0, 0, 0, 1, 0, -122.527, -121.509, 0, 1);
	}

	4.7% {
		transform: matrix3d(0.45, 0, 0, 0, 0, 0.599, 0, 0, 0, 0, 1, 0, -134.908, -134.843, 0, 1);
	}

	6.81% {
		transform: matrix3d(0.659, 0, 0, 0, 0, 0.893, 0, 0, 0, 0, 1, 0, -197.77, -200.879, 0, 1);
	}

	8.61% {
		transform: matrix3d(0.82, 0, 0, 0, 0, 1.097, 0, 0, 0, 0, 1, 0, -245.972, -246.757, 0, 1);
	}

	9.41% {
		transform: matrix3d(0.883, 0, 0, 0, 0, 1.168, 0, 0, 0, 0, 1, 0, -265.038, -262.804, 0, 1);
	}

	10.21% {
		transform: matrix3d(0.942, 0, 0, 0, 0, 1.226, 0, 0, 0, 0, 1, 0, -282.462, -275.93, 0, 1);
	}

	12.91% {
		transform: matrix3d(1.094, 0, 0, 0, 0, 1.328, 0, 0, 0, 0, 1, 0, -328.332, -298.813, 0, 1);
	}

	13.61% {
		transform: matrix3d(1.123, 0, 0, 0, 0, 1.332, 0, 0, 0, 0, 1, 0, -336.934, -299.783, 0, 1);
	}

	14.11% {
		transform: matrix3d(1.141, 0, 0, 0, 0, 1.331, 0, 0, 0, 0, 1, 0, -342.273, -299.395, 0, 1);
	}

	17.22% {
		transform: matrix3d(1.205, 0, 0, 0, 0, 1.252, 0, 0, 0, 0, 1, 0, -361.606, -281.592, 0, 1);
	}

	17.52% {
		transform: matrix3d(1.208, 0, 0, 0, 0, 1.239, 0, 0, 0, 0, 1, 0, -362.348, -278.88, 0, 1);
	}

	18.72% {
		transform: matrix3d(1.212, 0, 0, 0, 0, 1.187, 0, 0, 0, 0, 1, 0, -363.633, -267.15, 0, 1);
	}

	21.32% {
		transform: matrix3d(1.196, 0, 0, 0, 0, 1.069, 0, 0, 0, 0, 1, 0, -358.864, -240.617, 0, 1);
	}

	24.32% {
		transform: matrix3d(1.151, 0, 0, 0, 0, 0.96, 0, 0, 0, 0, 1, 0, -345.164, -216.073, 0, 1);
	}

	25.23% {
		transform: matrix3d(1.134, 0, 0, 0, 0, 0.938, 0, 0, 0, 0, 1, 0, -340.193, -210.948, 0, 1);
	}

	28.33% {
		transform: matrix3d(1.075, 0, 0, 0, 0, 0.898, 0, 0, 0, 0, 1, 0, -322.647, -202.048, 0, 1);
	}

	29.03% {
		transform: matrix3d(1.063, 0, 0, 0, 0, 0.897, 0, 0, 0, 0, 1, 0, -318.884, -201.771, 0, 1);
	}

	29.93% {
		transform: matrix3d(1.048, 0, 0, 0, 0, 0.899, 0, 0, 0, 0, 1, 0, -314.277, -202.202, 0, 1);
	}

	35.54% {
		transform: matrix3d(0.979, 0, 0, 0, 0, 0.962, 0, 0, 0, 0, 1, 0, -293.828, -216.499, 0, 1);
	}

	36.74% {
		transform: matrix3d(0.972, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, -291.489, -220.242, 0, 1);
	}

	39.44% {
		transform: matrix3d(0.962, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, -288.62, -227.228, 0, 1);
	}

	41.04% {
		transform: matrix3d(0.961, 0, 0, 0, 0, 1.022, 0, 0, 0, 0, 1, 0, -288.247, -229.999, 0, 1);
	}

	44.44% {
		transform: matrix3d(0.966, 0, 0, 0, 0, 1.032, 0, 0, 0, 0, 1, 0, -289.763, -232.215, 0, 1);
	}

	52.15% {
		transform: matrix3d(0.991, 0, 0, 0, 0, 1.006, 0, 0, 0, 0, 1, 0, -297.363, -226.449, 0, 1);
	}

	59.86% {
		transform: matrix3d(1.006, 0, 0, 0, 0, 0.99, 0, 0, 0, 0, 1, 0, -301.813, -222.759, 0, 1);
	}

	61.66% {
		transform: matrix3d(1.007, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, -302.102, -222.926, 0, 1);
	}

	63.26% {
		transform: matrix3d(1.007, 0, 0, 0, 0, 0.992, 0, 0, 0, 0, 1, 0, -302.171, -223.276, 0, 1);
	}

	75.28% {
		transform: matrix3d(1.001, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, -300.341, -225.696, 0, 1);
	}

	83.98% {
		transform: matrix3d(0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -299.61, -225.049, 0, 1);
	}

	85.49% {
		transform: matrix3d(0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -299.599, -224.94, 0, 1);
	}

	90.69% {
		transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, -299.705, -224.784, 0, 1);
	}

	100% {
		transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -300, -225, 0, 1);
	}
}

@media(max-width: 767px) {
	.popup.pop figure {
		left: 55%;
		width: 100%;
		max-width: 550px;
		// margin-left: -37.5%;
	}

	.popup.pop img {
		width: 100%;
	}
}

@media(max-width: 576px) {
	.popup.pop figure {
		left: 65%;
		max-width: 450px;
	}
}

@media(max-width: 480px) {
	.popup.pop figure {
		max-width: 360px;
		left: 80%;
	}
}

@media(max-width: 380px) {
	.popup.pop figure {
		left: 95%;
		max-width: 300px;
	}
}